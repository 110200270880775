.modalAnchor {
  position: absolute;
}

.modalView {
  position: absolute;

  // z-index: 50;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // cursor: default;
  // pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;

  // position: fixed; /* Stay in place */
  z-index: 500; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
}

.exitButton {
  position: absolute;
  top: 5px;
  right: 5px;
  color: black;
  font-size: 350%;
  cursor: pointer;
}

.exitButton:hover {
  transform: scale(1.1);
}

.modalContainer {
  // position: fixed;
  // z-index: 1;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  // overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modalContent {
  position: relative;
  width: max-content;
  display: contents;
}

.modal {
  display: flex;
  max-height: 100vh;

  position: absolute;
  width: max-content;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 50%;
  min-height: 50%;
  // top: 50%;
  // left: 50%;
  // transform: translateY(-50%) translateX(-50%);
  // // margin: 15% auto; /* 15% from the top and centered */
  // padding: 20px;
  // border: 1px solid #888;
  // width: 80%; /* Could be more or less, depending on screen size */
}

.hidden {
  display: none;
}

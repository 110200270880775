@import "../common-styles.scss";
@import "../fonts.scss";

.card {
  @extend .panel;

  border-radius: 3px;
  transition: all 0.1s;
  background-color: rgba(var(--card-background));
  box-shadow: 0px 0px 5px 0px rgba(var(--card-border-color));

  color: rgb(var(--color-panel-text));
  align-items: center;
  overflow: hidden;
}

.small {
  width: 9rem;
  height: 9rem;
}

.medium {
  width: 12rem;
  height: 12rem;
}

.large {
  width: 16rem;
  height: 16rem;
}

.small-tall {
  height: 10rem;
}

.medium-tall {
  height: 14rem;
}

.large-tall {
  height: 18rem;
}

@media (max-width: 30rem) {
  .small {
    width: 30vw;
    height: 30vw;
  }

  .medium {
    width: 40vw;
    height: 48vw;
  }

  .large {
    width: 100vw;
    height: 100vw;
  }

  .small-tall,
  .medium-tall,
  .large-tall {
    height: 25vh;
  }
}
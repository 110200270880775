.column {
  max-width: 40rem;
  width: 100%;
}

.headerSection {
  width: 100%;
}

.resumeImageContainer {
  width: 15rem;
  height: 15rem;
  margin-top: 1rem;
  border-radius: 50%;
  border-style: solid;
  box-shadow: 0 0 2px 0 black;
}

.resumeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search {
  height: 2rem;
}

.resumeHeader {
  width: 100%;

  text-align: center;
  background: rgb(140,176,20);
  background: -moz-linear-gradient(180deg, rgb(38, 137, 183) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(38, 137, 183, 1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(38, 137, 183, 1) 0%, rgba(255,255,255,1) 40%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8cb014",endColorstr="#ffffff",GradientType=1);
}

.resumeInfo {
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  width: 100%;
}

.resumeName {
  margin: 1rem;
  font-weight: bold;
  text-transform: capitalize;
}

.info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem;
}

.verifiedBlock {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  width: 50%;
  gap: .5rem;
}

.headerUrls {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}

.searchBar {
  width: 100%;
  max-width: 20rem;
  margin: 1rem;
  margin-right: 2rem;
}

.contactButton {
  align-self: center;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.carouselImage {
  width: 100%;
  height: 30rem;
  object-fit: contain;
}
@import "../common-styles.scss";

.image {
  vertical-align: middle;
  margin: auto;
  width: 100%;
  height: 100%;
  // width: 200%;
  // height: 200%;
  // transform: translateX(-25%) translateY(-25%) scale(.5);
}

.round {
  border-radius: 50%;
}

.image.fill {
  object-fit: fill;
}
.contain {
  object-fit: contain;
}
.cover {
  object-fit: cover;
}
.scaleDown {
  object-fit: scale-down;
}

.clickable {
  cursor: pointer;
}

.notLoaded {
  display: none;
}
.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel {
  width: 100%;
  max-width: 30rem;
}

.content {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loadingWrapper {
  display: flex;
}

.loading {
  margin: auto;
}

.floating {
  position: absolute;
  height: 128px;
  right: 0;
  left: 0;
  bottom: 0px;
}

.loading svg {
  height: 100%;
}

.floating .loading {
}

.small {
  display: flex;
  height: 2rem;
}

.small svg {
  height: 2rem;
}
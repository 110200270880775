:root {
  /**
  --color-background: rgba(10, 10, 10, 1.0);
  --color-clickable-hover: 0, 150, 250, 0.9;
  --color-panel-primary: 34, 71, 107, 0.95;
  --color-panel-inner: 0, 100, 200, 1.0;

  --color-background: rgba(255, 255, 255, 1.0);
  --color-panel-primary: 30, 33, 42, 1.0;  
  --color-panel-inner: 0, 100, 200, 1.0;
  **/

  --color-clickable-hover: 230, 230, 230, 1.0;
  --color-clickable-background: 230, 230, 230, 1.0;
  --card-background: 255, 255, 255, 1.0;
  --card-border-color: 0, 0, 0, 1.0;

  --color-border-primary: 0, 150, 150, 1.0;
  --color-background: rgba(240, 240, 240, 1.0);
  --color-panel-primary: 255, 255, 255, 1.0;

  --color-button-primary: rgb(2, 26, 26);
  --color-button-text: black;
  --color-button-border: #22476b;

  --color-button-hover: black;
  --color-panel-secondary: rgb(240, 100, 0);
  --color-panel-border: grey;
  --color-panel-text: black;
  --color-panel-hover: rgb(220, 220, 220);
  --color-panel-text-hover: black;
  --color-topnav-background: rgb(38, 137, 183);
  --color-topnav-text: white;
  --color-text-secondary: rgb(100, 100, 100);;
}

html, body > #root > div {
  /* height: 100%; */
}

body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-background);

  margin: 0;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*
*::selection { background: none; } 
*::-moz-selection { background: none; }
*/

button {
  background-color: inherit;
}

button:hover {
  background-color: inherit;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  color: var(--primary-text-color);
}

/*
Bunch of css to remove annoying default styling.
*/

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@import "../common-styles.scss";

.panel {
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  overflow: hidden;
  height: max-content;
}

.fill {
  height: 100%;
}

@media(max-width: 30rem) {
  .panel {
    margin: 0;
  }
}

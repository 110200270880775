@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page {
  overflow: hidden;
  // Adjusting for padding and topnav.
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin-top: 60px;
  margin-bottom: 5rem;

  transition: all 0.5s;
  animation: fade-in 0.5s;
}
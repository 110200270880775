.card {
  box-shadow: 0 0 2px 1px grey;
}

.cardImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin: auto;
}

.referenceContactCard {
  @extend .card;

  margin: .5rem;
  border-radius: 7px;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topnav {
  // position: absolute;
  // z-index: 10;

  top: 0;
  left: auto;
  right: 0;
  // position: fixed;

  width: 100%;
  background-color: var(--color-topnav-background);
  border-bottom: 1px solid #eee;
  color: var(--color-topnav-text);

  height: 59px;
  display: inline-flex;
}

.menuIcon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1.5rem;
}

.logo {
  height: 59px;
  width: 59px;
  border: none;
  margin-left: 1rem;
}

.logo img {
  min-height: unset;
  min-width: unset;
}

.topTitle {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  padding-left: 1rem;
  color: var(--primary-gray);
  border-left: 2px solid var(--primary-gray);
}

.icons {
  margin-left: auto;
  margin-right: 1rem;
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navIcon {
}
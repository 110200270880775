.item {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.referenceItem {
  @extend .item;
}

.referenceName {
  margin-left: 1rem;
  text-transform: capitalize;
}

.documentItem {
  @extend .item;
  gap: 1rem;
}

.documentDescription {
  font-size: 10px;
  overflow: hidden;
  display: -webkit-box;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.documentSubinfo {
  margin-left: 1rem;
  width: 20%;
  overflow: hidden;
}

.documentStatus {
  margin-left: 1rem;
  font-size: 8px;
  width: 5rem;
  text-transform: capitalize;
}

.documentIndustry {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 10px;
}

.documentTags {
  font-size: 8px;
}

.documentThumb {
  height: 100%;
  width: 10rem;
  object-fit: cover;
  margin-left: auto;
}

.questionItem {
  padding-left: 1rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.questionEditItem {
  padding-left: 1rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.questionReviewItem {
  max-width: 40rem;
  width: 100%;
  padding-left: 1rem;
  height: 14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.questionEditIcon {
  padding: .25rem;
  box-shadow: 0 0 3px grey;
}

.list {
  font-size: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reviewQuestionList {
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
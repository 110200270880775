.break {
  padding: 0;
  width: calc(100%);
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin: 0;
  border-style: solid;
  border-color: gray;
  border-width: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
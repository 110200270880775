/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: -128px; opacity: 0;}
  to {bottom: 0px; opacity: 1;}
}

@keyframes fadeout {
  from {bottom: 0px; opacity: 1;}
  to {bottom: -128px; opacity: 0;}
}

.toast {
  z-index: 10;
  justify-content: center;
  text-align: center;
  position: absolute;
  height: 128px;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.25s;
  visibility: hidden;
  opacity: 0;
}

.show {
  visibility: visible;
  opacity: 1;
}
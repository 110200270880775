.tooltip {
  transition: all 0.5s;
}

/* Tooltip text */
.content {
  display: none;
  transform: translateX(-100%) translateY(-100%);
  transition: all 0.5s;
 
  /* Position the tooltip text - see examples below! */
  position: fixed;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .content {
  display: block;
  visibility: visible;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  margin-top: 1rem;
}
.grid {
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  gap: 1rem;

  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 30rem) {
  .grid {
    justify-content: center;
  }
}

.center {
  justify-content: center;
}
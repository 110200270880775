@import '../common-styles.scss';

.icon {
  transition: all 0.1s;
  font-size: 100%;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  display: inline;
  color: var(--color-panel-text-hover);
}

.large {
  font-size: 250%;
}

.clickable:hover {
  @extend .clickable;
  background-color: unset;
}

@media (max-width: 500px) {
  .large {
    font-size: 300%;
  }
}
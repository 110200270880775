@import '../common-styles.scss';

.button {
  @extend .border;
  @extend .panel;
  @extend .clickable;

  border-radius: 1.6rem;

  // background-color: var(--color-button-primary);
  border: 1px solid var(--color-button-border);
  margin: 1rem;
  padding: 8px 16px;
  font-size: 24px;
  transition: all 0.2s;
  cursor: pointer;
  color: var(--color-button-text);
}

.button * {
  margin: .2rem;
}

.button:disabled {
  transform: none;
  cursor: default;
  background-color: white;
  color: #E2E3E2;
  border: 1px solid #E2E3E2;
}

.round {
  border-radius: 50%;
}

.flat {
  border: none;
  border-radius: unset;
}

.active {
  transform: none;
  background-color: white;
  color: #E2E3E2;
  border: 1px solid #E2E3E2;
}

.cover {
  margin: unset;
  width: 100%;
  height: 100%;
}
.font-xsmall {
  font-size: 12px;
}

.font-small {
  font-size: 18px;
}

.font-medium {
  font-size: 24px;
}

.font-large {
  font-size: 30px;
}
input {
  all: unset;
}

.textInput {
  border-radius: 3px;
  box-shadow: 0 0 2px 1px grey;
  font-size: 14px;
  height: 100%;
  min-height: 2.5rem;
  width: 100%;
}

.textInput input {
  font-size: 14px;
  width: calc(100% - 2.5rem);
  padding: .75rem;
  padding-left: 1.5rem;
  align-items: center;
}

textarea:focus, input:focus{
  outline: 0;
}

.textInput:disabled {
  color: lightgrey;
}

.area {
  padding: 10px;
  width: calc(100% - 2rem - 20px);
  min-height: 5rem;
}

textarea {
  border: none;
  margin: 1rem;
  resize: vertical;
}

.label {
  position: absolute;
  pointer-events: none;
  color: var(--color-topnav-background);
  transform: translateX(10px) translateY(2px);
  font-size: 10px;
}
.text {
  text-align: center;

  display: -webkit-box;
  color: inherit;

  word-break: break-word;
  line-height: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
}

.capitalize {
  text-transform: capitalize;
}

.xsmall {
  font-size: 10px;
}

.small {
  font-size: 10px;
}

.medium {
  font-size: 14px;
}

.large {
  font-size: 18px;
}

.bold {
  font-style: bold;
}

.error {
  color: red;
}
.border {
  transition: all 0.1s;
  border-radius: 3px;
  border: 3px solid rgba(var(--color-border-primary));
}

.rounded {
  border-radius: 7px;
}

.clickable {
  transition: all 0.1s;
  cursor: pointer;
}

.clickable:hover {
  background-color: rgba(var(--color-clickable-background));
  // box-shadow: 0px -5px 15px 5px rgba(var(--color-clickable-hover));
}
// .clickable:hover {
//   // background-color: var(--color-panel-hover);
//   // color: rgb(var(--color-panel-text-hover));
// }

.panel {
  transition: all 0.1s;
  // border: 5px solid rgba(var(--color-border-primary));
  box-shadow: inset 0px 0px 5px 1px rgba(var(--color-panel-inner));
  // box-shadow: inset 0px 0px 5px 0px rgba(var(--color-panel-inner));
  background-color: rgba(var(--color-panel-primary));
  color: rgb(var(--color-panel-text));
}

.raised {
  box-shadow: 0px 0px 10px 0px rgba(var(--color-panel-inner));
}